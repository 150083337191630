<template>
  <v-list-item
    link
    :to="path"
  >
    <v-list-item-action>
      <v-icon>{{ icon }}</v-icon>
    </v-list-item-action>

    <v-list-item-content>
      <v-list-item-title>{{ name }}</v-list-item-title>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
export default {
  props: [
    'icon',
    'name',
    'path'
  ]
}
</script>

<style lang="scss" scoped>

</style>
